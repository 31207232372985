<template>
    <div class="otherwrap">
        <div class="left-block">
            <div class="inner">
                <section v-for="(item,index) in slideValueList" :key="index">
                    <div :class="{'hoverNow': currentIndex == index}">
                        <label>{{ item.name }}</label>
                        <a-switch v-model:checked="item.open" @change="(value)=>handleStatusChange(value, item)"/>
                    </div>
                </section>
            </div>  
        </div>
        <div class="right-block">
            <div class="mark-wrap">
                <div class="marks" ref="marksRef">
                    <span><em>00:00</em></span>
                    <span><em>01:00</em></span>
                    <span><em>02:00</em></span>
                    <span><em>03:00</em></span>
                    <span><em>04:00</em></span>
                    <span><em>05:00</em></span>
                    <span><em>06:00</em></span>
                    <span><em>07:00</em></span>
                    <span><em>08:00</em></span>
                    <span><em>09:00</em></span>
                    <span><em>10:00</em></span>
                    <span><em>11:00</em></span>
                    <span><em>12:00</em></span>
                    <span><em>13:00</em></span>
                    <span><em>14:00</em></span>
                    <span><em>15:00</em></span>
                    <span><em>16:00</em></span>
                    <span><em>17:00</em></span>
                    <span><em>18:00</em></span>
                    <span><em>19:00</em></span>
                    <span><em>20:00</em></span>
                    <span><em>21:00</em></span>
                    <span><em>22:00</em></span>
                    <span><em>23:00</em></span>
                    <span><em>24:00</em></span>
                </div>
                <div class="slides" ref="slidesRef" >
                    <template v-for="(item,index) in slideValueList" :key="index">
                        <div @mouseenter="currentIndex = index" @mouseleave="currentIndex = null">
                            <a-slider :getTooltipPopupContainer="triggerNode => triggerNode.parentNode" :disabled="item.open" @change="(value)=>handleDealChange(value, item)"  :tip-formatter="(value)=>formatter(value,item)" tooltipVisible  class="custom-slider" :class="{'kuaye': item.kuayeFlag}"  :min="0" :max="24" :step="0.5" range  id="test" v-model:value.lazy="item.value" />
                        </div>
                    </template>
                </div>
                
            </div>
            
        </div>
        
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import {message} from 'ant-design-vue'
import { apiRuleControlManage } from '@/api/IoT/ruleManage.js'
const slidesRef = ref(null)
const marksRef = ref(null)
const currentIndex = ref(null)
const slideValueList = ref([
    {
        value:[8, 18],
        kuayeFlag: false,
        min:8,
        max:18,
    },
    {
        value:[6, 22],
        kuayeFlag: true,
        min:6,
        max:22,
    }
]);

const formatter = (value) => {
    let _value = (value+'').split('.')
    let hour = _value[0].length == 1?0+_value[0]:_value[0]
    let minute = _value[1]?'30':'00'

    return `${hour}:${minute}`;
    
};

const handleDealChange = (value, item)=>{

    let first = value[0]
    let second = value[1]
    if(!item.kuayeFlag){

        if(item.compare_min != item.compare_max){
            if(item.compare_max == second){
                item.kuayeFlag = false
            }
            if(item.compare_min == first){
                item.kuayeFlag = false
            }
            if(item.compare_max == first){
                item.kuayeFlag = true
            }

            if(item.compare_min == second){
                item.kuayeFlag = true
            }
        }
    
        item.compare_min = first
        item.compare_max = second
    }else{
        if(item.compare_min != item.compare_max){
            if(item.compare_max == second){
                item.kuayeFlag = true
            }
            if(item.compare_min == first){
                item.kuayeFlag = true
            }
            if(item.compare_max == first){
                item.kuayeFlag = false
            }

            if(item.compare_min == second){
                item.kuayeFlag = false
            }
        } 
        item.compare_min = first
        item.compare_max = second
    }
    item.compare_min = first
    item.compare_max = second
}

const initList = ()=>{
    apiRuleControlManage.list({
        bizId: 'PJ00001021'
    }).then(res=>{
        if(Array.isArray(res.result)){
            slideValueList.value = [];
            res.result.forEach(item=>{
                let start = transTime(item.targetOnTime)
                let end = transTime(item.targetOffTime)
                let kuayeFlag = start*1>end*1;
                if(kuayeFlag){
                    start = transTime(item.targetOffTime)
                    end = transTime(item.targetOnTime)
                }
                slideValueList.value.push({
                    name:item.name,
                    ruleIds: item.ruleIds,
                    open: item.status == '01',
                    value:[start, end],
                    kuayeFlag,
                    min:start,
                    max:end,
                })
                
            })
            setTimeout(()=>{
                let slidesHeight =  slidesRef.value.getBoundingClientRect().height
                let minHeight = window.innerHeight - 150
                marksRef.value.style.height = slidesHeight<minHeight?minHeight+'px':slidesHeight+'px'
            },0)
            
        }
        console.log(2221, res.result)
    })
}
const transTime = (time)=>{
    let hour = time.split(':')[0]
    let minute = time.split(':')[1]
    hour = hour[0] == 0?hour[1]:hour
    return minute == '00'?hour:`${hour}.5`
}
const handleStatusChange = (value, item)=>{
    apiRuleControlManage.changeStatus({
        bizProjId: 'PJ00001021',
        ruleIds: item.ruleIds,
        status: value?'01':'02'
    }).then(()=>{
        message.warning("状态修改成功！");
        initList();
    })

    if(value){
        let targetOnTime;
        let targetOffTime;
        if(item.kuayeFlag){
            targetOnTime = formatter(item.value[1])
            targetOffTime = formatter(item.value[0])
        }else{
            targetOnTime = formatter(item.value[0])
            targetOffTime = formatter(item.value[1])
        }
        apiRuleControlManage.changeTime({
            bizProjId: 'PJ00001021',
            ruleIds: item.ruleIds,
            targetOnTime,
            targetOffTime 
        }).then(()=>{
            message.warning("状态修改成功！");
            initList();
        })
    }
}
onMounted(()=>{
    initList()
    console.log('init')
})
</script>
<style lang='less' scoped>
.otherwrap{
    display: flex;
    height: 100%;
}
.left-block{
    width: 220px;
    height: 100%;
    //height: 100vh;
    padding-top: 40px;
    padding-bottom: 40px;
    .inner{
        padding-top: 40px;

        &>section{
            display: flex;
            align-items: center;
            height: 80px;
            padding: 10px;
            &>div{
                display: flex;
                width: 200px;
                height: 50px;
                align-items: center;
                justify-content: center;
                border: 1px solid #aaa;
                background: #fff;
                border-radius: 4px;
                label{
                    width: 120px;
                    text-align: right;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-right: 10px;
                }
                &.hoverNow{
                    border: 1px solid #7db5ff;
                    background: #7db5ff;
                    color: #fff;
                }
            }
        }
    }
}
.right-block{
    flex: 1;
    height: 100%;
    padding: 40px 20px;
}
.mark-wrap{
    position: relative;
}
.marks{
    position: absolute;
    display: flex;
    width:100%;
    left: 0.4%;
    height: calc(100vh - 150px);
    span{
        position: relative;
        width: 4%;
        height: 100%;
        border-left: 1px dashed #aaa;
        em{
            position: absolute;
            top: -25px;
            left: -18px;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
        }
        
    }
}
.slides{
    width: 97%;
    padding-top: 40px;
    &>div{
        height: 80px;
        padding-top: 20px;
    }
}
.custom-slider{
    height: 40px;
    /deep/ .ant-slider-rail,
    /deep/ .ant-slider-track,
    /deep/ .ant-slider-step{
        height: 14px;
    }
    /deep/.ant-slider-handle{
        width: 24px;
        height: 24px;
    }
}

/deep/ .ant-slider-track{
    background-color: #888;
}
/deep/ .ant-slider-disabled .ant-slider-track {
    background-color: #7db5ff !important;
}
.kuaye{
    /deep/ .ant-slider-rail{
        background-color: #888;
    }
    /deep/ .ant-slider-track{
        background-color: #f5f5f5;
    }
    /deep/ &.ant-slider-disabled .ant-slider-rail {
        background-color: #7db5ff !important;
    }
    /deep/ &.ant-slider-disabled .ant-slider-track {
        background-color: #f5f5f5 !important;
    }

}
</style>